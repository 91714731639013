import React, { Component } from 'react';

import { connect } from 'react-redux';

import { dispatch as CustomDispatch } from 'redux/actions';

import LoadingView from 'views/loading';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = { checking: true };
  }

  componentDidMount() {
    this.checkUserStatus();
  }
  checkUserStatus() {
    this.props.CustomDispatch('checkUserStatus').finally(() => {
      this.setState({ checking: false });
    });
  }

  render() {
    if (this.state.checking) {
      if (!this.props.loading) return <LoadingView />;
      return <></>;
    }
    return <>{this.props.children}</>;
  }
}

export default connect(null, { CustomDispatch })(Auth);
