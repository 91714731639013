import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Auth from 'Auth';
import Authorization from 'Authorization';
import LoadingView from 'views/loading';
import MainLayout from 'layouts/MainLayout';
import Login from 'views/auths/login';
import Register from 'views/auths/register';
import history from 'browserHistory';
import { selectLoading, selectError } from 'redux/selectors/index';
import { sendTokenViaSocket } from 'assets/js/common';
import { SocketContext } from './SocketIO';

window.Apex = {
  chart: {
    foreColor: '#ccc',
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    theme: 'dark',
  },
  grid: {
    borderColor: '#535A6C',
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
};

function App() {
  const socket = useContext(SocketContext);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const [connected, setConnected] = useState(true);
  const [isInitialConnect, setIsInitialConnect] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      sendTokenViaSocket(socket);
    }, 1500);
    const onConnect = () => {
      sendTokenViaSocket(socket);
      clearTimeout(timer);
      setConnected(true);

      // Signal chart to refresh on reconnect, but not on initial connection
      if (!isInitialConnect) {
        window.chartShouldRefresh = true
      }
    };

    const onDisconnect = () => {
      setConnected(false);
      setIsInitialConnect(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket, isInitialConnect]);

  const isLoading = !connected || loading;

  return (
    <>
      {isLoading && <LoadingView />}
      <Auth loading={isLoading}>
        <Router history={history}>
          <Authorization connected={connected}>
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/" component={MainLayout} />
            </Switch>
          </Authorization>
        </Router>
      </Auth>
    </>
  );
}

export default App;

