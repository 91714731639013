import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectIsAuth, selectIsRegistered } from 'redux/selectors';

class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessGranted: true,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted || nextProps.isRegistered !== this.props.isRegistered;
  }

  static getDerivedStateFromProps(props, state) {
    const { isAuth, isRegistered, history } = props;
    const { pathname } = history.location;
    let accessGranted = isAuth;
    if (isAuth) {
      if (['/login', '/register'].includes(pathname)) accessGranted = false;
    } else {
      if (pathname === '/login' && isRegistered) accessGranted = true;
      else if (pathname === '/register' && !isRegistered) accessGranted = true;
    }
    return {
      accessGranted,
    };
  }

  redirectRoute(props) {
    const { isAuth, isRegistered, history, connected } = props;
    const { pathname, state } = history.location;

    if (!isRegistered && connected) {
      console.log('register');
      history.push({
        pathname: '/register',
        state: { redirectUrl: pathname },
      });
    } else if (!isAuth) {
      console.log('login');
      history.push({
        pathname: '/login',
        state: { redirectUrl: pathname },
      });
    } else {
      const redirectUrl = state && state.redirectUrl && state.redirectUrl !== '/register' ? state.redirectUrl : '/';

      history.push({
        pathname: redirectUrl,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { accessGranted } = this.state;
    return accessGranted ? <>{children}</> : null;
  }
}

function mapStateToProps(state) {
  return {
    isAuth: selectIsAuth(state),
    isRegistered: selectIsRegistered(state),
  };
}

export default withRouter(connect(mapStateToProps)(Authorization));
